﻿export function render_recaptcha(dotNetObj, selector, sitekey) { 
    return grecaptcha.render(selector, {
        'sitekey': sitekey,
        'callback': (response) => { dotNetObj.invokeMethodAsync('CallbackOnSuccess', response); },
        'expired-callback': () => { dotNetObj.invokeMethodAsync('CallbackOnExpired'); }
    });  
};

export function rescale_reCaptcha() {
    var width = $('.custom-recaptcha').parent().width();
    var scale;
    if (width < 302) {
        scale = width / 302;
    } else {
        scale = 1.0; 
    }

    $('.custom-recaptcha').css('transform', 'scale(' + scale + ')');
    $('.custom-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
    $('.custom-recaptcha').css('transform-origin', '0 0');
    $('.custom-recaptcha').css('-webkit-transform-origin', '0 0');

};