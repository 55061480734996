﻿export function initDatePicker(element, options) {
    flatpickr(element, {
        dateFormat: options.dateFormat,
        enableTime: options.enableTime || false,
        noCalendar: options.noCalendar || false,
        locale: options.locale,
        time_24hr: options.time_24hr || false
    });
}

export function acceptCookieMessage(cookieString) {
    document.cookie = cookieString;
}

export async function downloadFileFromStream(fileName, contentStreamReference) {
    const arrayBuffer = await contentStreamReference.arrayBuffer();
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const anchorElement = document.createElement('a');
    anchorElement.href = url;

    if (fileName) {
        anchorElement.download = fileName;
    }

    anchorElement.target = "_blank";
    document.body.appendChild(anchorElement);
    anchorElement.innerText = "Click to download!";
    anchorElement.click();

    document.body.removeChild(anchorElement);
    anchorElement.remove();

    URL.revokeObjectURL(url);
}

export function showCheckMark(profileId, type) {
    document.getElementById(`${type}Checkmark-${profileId}`).style.display = 'inline';
}

export function clickHiddenButton(buttonId) {
    document.getElementById(buttonId).click();
}

export function setImageSrc(elementId, src) {
    var imgElement = document.getElementById(elementId);
    if (imgElement) {
        // Append cache-busting query string (timestamp) to the image source
        imgElement.src = src + '?' + new Date().getTime();
    }
}

export function showContent(placeholderId, contentId) {
    document.getElementById(placeholderId).classList.add('d-none');
    document.getElementById(contentId).classList.remove('d-none');
    document.getElementById(contentId).classList.add('d-block');
}