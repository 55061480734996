// js dependencies
import 'jquery';
import '@popperjs/core';
import 'bootstrap';
import 'slick-carousel';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import '@fortawesome/fontawesome-free/js/all.js';
import 'flatpickr';

// css dependencies
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'flatpickr/dist/flatpickr.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

// own css
import '../css/style.css';

// own scripts
import { runCaptcha } from './vendors/GoogleReCaptcha.js';
import { bannerCarouselOne, bannerCarouselMultiple, pageSlider, teamCarousel } from './modules/carousel.js';
import { initDatePicker, acceptCookieMessage, downloadFileFromStream, showCheckMark, clickHiddenButton, setImageSrc, showContent } from './modules/utilities.js';

window.S1ProApp = window.S1ProApp || {};

$(document).ready(function () {
    S1ProApp.runCaptcha = runCaptcha;
    S1ProApp.bannerCarouselOne = bannerCarouselOne;
    S1ProApp.bannerCarouselMultiple = bannerCarouselMultiple;
    S1ProApp.pageSlider = pageSlider;
    S1ProApp.teamCarousel = teamCarousel;
    S1ProApp.initDatePicker = initDatePicker;
    S1ProApp.acceptCookieMessage = acceptCookieMessage;
    S1ProApp.downloadFileFromStream = downloadFileFromStream;
    S1ProApp.showCheckMark = showCheckMark;
    S1ProApp.clickHiddenButton = clickHiddenButton;
    S1ProApp.setImageSrc = setImageSrc;
    S1ProApp.showContent = showContent;

    bannerCarouselOne();
    bannerCarouselMultiple();
    pageSlider();
    teamCarousel();
});